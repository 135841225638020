<template>
  <component :is="role" :id="id" :init="init"></component>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProjectDetail',
  props: {
    id: Number,
    init: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
      'role',
    ]),
  },
  components: {
    admin: () => import(/* webpackChunkName: "admin" */'@/views/Admin/ProjectDetail.vue'),
    manager: () => import(/* webpackChunkName: "user" */'@/views/User/ProjectDetail.vue'),
  },
};
</script>
