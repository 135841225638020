<template>
  <component :is="role"></component>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Projects',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line
      vm.savedPosition = window.event?.type === 'popstate';
    });
  },
  data() {
    return {
      savedPosition: false,
    };
  },
  computed: {
    ...mapState([
      'role',
    ]),
  },
  components: {
    admin: () => import(/* webpackChunkName: "admin" */'@/views/Admin/Projects.vue'),
    manager: () => import(/* webpackChunkName: "user" */'@/views/User/Projects.vue'),
  },
};
</script>
